import Home from './pages/Home/Home'
import About from './pages/About/About'
import './App.css'
import {
    BrowserRouter as Router,
    Route,
Switch,
     } from "react-router-dom";
import React from 'react';

const App = (props) => {



console.log('hello from app')
    return (
        <div id='app'>
<nav>
    <ul>
      <li>
        <h1>
          <a href='/'>
            <span className="fa-solid fa-venus" aria-hidden="true">
             Joeleen</span>
          </a>
        </h1>
      </li>
      <li 
      // onClick={(e)=> {
      //   // e.preventDefault()
      //   // props.history.push('/projects')
      
      //   }  }
      >
        <a href='/projects'>Projects</a>
      </li>
      <li><a href='/about'>About</a></li>
      <li><a href='#contact'>Contact</a></li>
      <li>
        <a href='https://www.linkedin.com/in/joeleenkado' rel="noreferrer" target="_blank">
          <i className="fa-brands fa-linkedin" aria-hidden="true"></i>
          
          <span className='sr-only' 
            >
            LinkedIn</span>
        </a>
      </li>
      <li>
        <a href='https://github.com/joeleenkado' rel="noreferrer" target="_blank">
          <i className="fa-brands fa-github" aria-hidden="true"></i>
          <span className='sr-only'> Github</span>
        </a>
      </li>
      <li><a rel="noreferrer" href='https://drive.google.com/file/d/1ETNBIXSZuCeCBbs7ArmUCcQfLCh7x4cH/view?usp=sharing' target="_blank" className="button">Resume</a></li>
    </ul>
  </nav>
<Router>
<Switch>
<Route
       exact path = '/'
          component ={Home}
/>

<Route exact path="/projects" component={Home} >
</Route>

<Route
         exact path='/about'
          component={About}
/>
</Switch>
    </Router>
<footer>
    <h2>Joeleen Kado &middot; Software Engineer</h2>
    <ul>
      <li>
    <a href='https://www.linkedin.com/in/joeleenkado' rel="noreferrer" target="_blank">
            <i className="fa-brands fa-linkedin" aria-hidden="true"></i>
          
            <span className='sr-only' 
              >
              LinkedIn</span>
          </a>  </li>   
    <li>
    <a href='https://github.com/joeleenkado' rel="noreferrer" target="_blank">
            <i className="fa-brands fa-github" aria-hidden="true"></i>
            <span className='sr-only'> Github</span>
          </a>
    </li>
    <li>
    <a href='mailto:joeleen.kado@gmail.com'>
            <i className="fa-solid fa-envelope" aria-hidden="true"></i>
            <span className='sr-only'> Email</span>
          </a>
    </li>
    </ul> 
    <p><small>
    &copy; 2022 Joeleen Kado. All rights reserved.
      </small>
      </p>
    </footer>
    </div>
)

}
export default App;