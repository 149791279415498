const Home = () => {

    return(
        <section id='home-page'>
<section id='intro'>
    <p className='name'>Hi, my name is <span>Joeleen Kado.</span></p>
  
    <h2>I do Fullstack Web Development.</h2>
    <p>
      I specialize in the PERN stack, and I am interested in testing, containerization/cloud computing, and mobile.</p>
  
    <p>Currently, I am a Volunteer React Developer at <a href='https://www.ptchatapp.com' target="_blank" rel="noreferrer"> PT Chat</a>, developing the administrative console of a mobile communication app for parents and teachers.</p>
  </section>
  <div className="gradient-270"></div>

    <div className='div-teal'>
    <section id='projects'>
    <h2>
    Projects I'm proud of
    </h2>
      <article>
        <div className='text'>
    {/* <p><a href="mailto:joeleen.kado@gmail.com" className="button">Email me</a></p> */}

        <h3 style={{ 'margin-right': '18rem' }}>
          {/* <br/> */}
          <a className='black-box' href='https://radiant-ravine-06436.herokuapp.com/' target='blank'>
          Virtual Gallery
          </a>
          </h3>
        <p className='black-box'>
          Artists, 
showing your work publicly is a big job. 
Virtual Gallery is an opportunity to immediately 
start sharing your art with the world. 
-Curate a gallery of your own work: build frames for your artwork in the Studio 
-share your art with friends; 
--and learn your strengths and weaknesses as an artist: other artists will like some of your paintings more than others. Through implementation of Joeleen's Like Filtration System, Your most popular paintings will be brought to the front of your Gallery.
      </p>    
    <h4>
    Technologies used include: 
      </h4>
      <ul >
      <a href='https://www.artic.edu/open-access/public-api'
         style={{'text-decoration': 'none'}} target='blank'>
        <li className='white-box' id='artic'>
          Art Institute Chicago API
        
          </li>
       
        </a>
      {/* <a style={{'text-decoration': 'none'}} href='https://www.postgresql.org/'> */}
      <li className='white-box' id='css'>CSS</li>
      <a href='https://www.filestack.com/' style={{'text-decoration': 'none'}} target='blank'> 
     
     <li className='white-box' id='filestack'>
     filestack API
      </li>
      </a>
        <li className='white-box' id='html'>
        
        HTML
         </li>
       {/* </a> */}
       {/* <a style={{'text-decoration': 'none'}} href='https://www.p.org/'> */}

    
   
   
   
   
    <a   style={{'text-decoration': 'none'}} href='https://www.javascript.com/' target='blank'>

    <li  id='js' className='white-box'
    >
    javascript
 
    </li>
  
      </a>

      <a href='https://nodejs.org/en/' style={{'text-decoration': 'none'}} target='blank'>
      

    <li className='white-box' id='node'>
      
   
      
      Node

      </li>
      </a>
     
     
      <a href='https://www.postgresql.org/' style={{'text-decoration': 'none'}} target='blank'>
      
    <li id='sql' className='white-box'>
      PostgreSQL
      </li>
    </a>
  
    <a href='https://react-redux.js.org/' style={{'text-decoration': 'none'}} target='blank'>

    <li className='white-box' id='redux'>
    React-Redux

     </li>


</a>
  

      


        </ul>
          </div>
          <img src="images/ScreenShot.png" alt="virtual-gallery" />
    </article>  
    </section>
      </div>
  <div className="gradient-90"></div>
<div className='div-violet'>
<section id='contact'>
    <h2>Contact me</h2>
    <p>My favorite part of Web development is sharing with others, to that end, I am open to opportunities and meaningful proffessional and personal connections. Let's share!</p>
    <p><a href="mailto:joeleen.kado@gmail.com" className="button">Email me</a></p>
  </section>
</div>
  <div className="gradient-270"></div>
</section>
  )
}

export default Home;