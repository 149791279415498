


const About = () => {
console.log('About.js')
    return (<section id='about-page'>
<section id='intro'>
    <p className='name'>Hi, my name is <span>Joeleen Kado.</span></p>
    <h2>Here is My Story:</h2>
    
    <p>I am, by nature an artist. My inquisitive, exporatory, creative nature is derived from this. 
        I've always wanted to know computers. I built my first computer when I was 17, a custom gaming
        machine so that I could play online roleplaying games. I was proud of my 
        growing computer acumen, and incidentally, I enlisted in the Navy. a year, after highschool graduation,
        and continued exploration into computers. I assigned the rating Information Systems Technician.
        My 4 years as an IT showed me the world and new opportunities for personal exploration; 
        One of the products of my enlisted term, is the completion of the Department of Labor's Computer Programmer Apprenticeship.
        After receiving an Honorable Discharge I attended Hawaii Pacific University and completed BA.
        Art became the focus of my life. 
        As art found a way back into my life, so recently did computers.
        In 2020, I enrolled into Prime Digital Academy to become 
        a certified Full Stack Software Engineer and I graduated in February 2021. Now, as a Software Engineer
        my passions find reconciliation: I process technology artistically, in my understanding, 
        and through my trait of creative problem solving. 
    </p>
  </section>
  <div className="gradient-90"></div>
<div className='div-teal'>
  <section id='about'>
   <article>
      <div className='text'>
  <h4>
  Hobbies include:  
    </h4>
    <ul>
    {/* <a href='https://www.artic.edu/open-access/public-api' */}
         {/* style={{'text-decoration': 'none'}}> */}
        <li className='yellow-box' id=''>
          Drawing
        
          </li>
          {/* </a> */}
            <li className='yellow-box'>Painting</li>
  <li className='yellow-box'>Dancing</li>
  <li className='yellow-box'>Running</li>
  <li className='yellow-box'>Volunteering</li>
  <li className='yellow-box'>Trying new things</li>
      </ul>
        </div>
        <img src="images/Diploma.jpg" alt="fse-certificate" />
  </article>  
  </section>
    </div>
<div className="gradient-90"></div>
<div className='div-violet'>
<section id='contact'>
  <h2>Contact me</h2>
  <p>My favorite part of Web development is sharing with others, to that end, I am open to opportunities and meaningful proffessional and personal connections. Let's share!</p>
  <p><a href="mailto:joeleen.kado@gmail.com" className="button">Email me</a></p>
</section>
</div>
<div className="gradient-270"></div>
    </section>
    )
}

export default About